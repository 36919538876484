import * as React from 'react'

import { ValueInterface } from '../selectInput/component'

import './styles.scss'

export interface GridSelectInterface {
  items: ValueInterface[]
  handleClick: Function
}

export const GridSelect: React.FC<GridSelectInterface> = ({ items, handleClick }: GridSelectInterface) => {
  const handleBankName = (name: string): string => {
    return name.replace(/\s/g, '').toLowerCase()
  }

  return items.length ? (
    <div className="grid-select">
      <div className="grid-content">
        {items.map((item) => (
          <div key={item.value} className="grid-item" onClick={(): void => handleClick(item)}>
            <div className={`grid-item__logo grid-item__logo--${handleBankName(item.value)}`} />
            <div className="grid-item__title">
              {item.name}
              {/* <span className="grid-item__arrow" /> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div />
  )
}

import React, { useContext, Fragment } from 'react'
import { observer } from 'mobx-react'
import { ProviderSelection } from '~components/providerSelection/providerSelection'
import { Consent } from '~components/consent/consent'
import { BankSelection } from '~components/bankSelection/bankSelection'
import { Error } from '~components/error/error'

import { StoreContext } from '~components/App'

export const Content: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const { currentScreen } = store.AppState
  const { page } = currentScreen

  return (
    <Fragment>
      {page === 'bank-selection' && <BankSelection />}
      {page === 'providers' && <ProviderSelection />}
      {page === 'consent' && <Consent />}
      {page === 'error' && <Error />}
    </Fragment>
  )
})

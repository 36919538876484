import i18n from 'i18next'
import translation from './en/translation.json'
import translationNl from './nl/translation.json'
import translationFi from './fi/translation.json'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const detector = new LanguageDetector()
detector.init({
  order: ['navigator', 'queryString', 'path', 'cookie'],
  lookupFromPathIndex: 0,
  lookupQuerystring: 'lng',
})

export const resources = {
  en: {
    translation: translation,
  },
  nl: {
    translation: translationNl,
  },
  fi: {
    translation: translationFi,
  },
} as const

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: 'en',
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'nl', 'fi'],
    // keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator', 'queryString', 'path', 'cookie'],
      lookupFromPathIndex: 0,
    },
  })

import * as React from 'react'

import './styles.scss'

export const Spinner: React.FC = () => {
  return (
    <div className="spinner">
      <svg className="circular" viewBox="25 25 50 50" width="48px" height="48px">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="2"
          stroke="black"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  )
}

export interface PageInterface {
  page: string
}

export const pages = {
  pageBankSelection: { page: 'bank-selection' },
  pageProviders: { page: 'providers' },
  pageConsent: { page: 'consent' },
  pageError: { page: 'error' },
}

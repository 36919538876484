import * as React from 'react'
import axios from 'axios'

import { getApiOrigin } from '~methods/getApiOrigin'
import './styles.scss'
import providerIconIdin from '~assets/providerIcon_idin.svg'
import providerIconNbid from '~assets/providerIcon_nbid.svg'
import providerIconFtn from '~assets/providerIcon_ftn.svg'
import providerIconPlaceholder from '~assets/providerIcon_placeholder.svg'
import '~i18n/config'
import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'
import { StoreContext } from '~components/App'
import { observer } from 'mobx-react'
import { Spinner } from '~components/spinner/spinner'

export const ProviderSelection: React.FC = observer(() => {
  const { t } = useTranslation()
  const store = useContext(StoreContext)
  const [loading, setLoading] = useState(true)
  const { providers, sgncBrand } = store.AppState

  const getProviders = async (): Promise<void> => {
    try {
      const response = await axios.get(`${getApiOrigin()}/facebook/providers`, { withCredentials: true })
      store.AppState.setProviders(response.data)
      setLoading(false)
    } catch (e) {
      store.AppState.setActivePage('error')
    }
  }

  const handleProviderSelect = (provider: { id: string }): void => {
    store.AppState.setSelectProvider(provider.id)
    if (provider.id === 'idin' || provider.id === 'ftn') {
      store.AppState.setActivePage('bank-selection')
    } else {
      window.location.href = `${getApiOrigin()}/facebook/proceedProvider/${provider.id}`
    }
  }

  const getProviderIcon = (id: string): string => {
    if (id === 'nbid' || id === 'nbid-mobile') {
      return providerIconNbid
    } else if (id === 'idin') {
      return providerIconIdin
    } else if (id === 'ftn') {
      return providerIconFtn
    } else {
      return providerIconPlaceholder
    }
  }

  React.useEffect(() => {
    //getProviders()
  }, [])

  return (
    <div className="provider-selection">
      <div className="provider-selection__content">
        <h3>{t('providers.listHeader')}</h3>
        {loading ? (
          <div className="block-centered">
            <Spinner />
          </div>
        ) : (
          <ul className="provider-list">
            {providers.map((provider) => (
              <li key={provider.id} className="provider-item" onClick={(): void => handleProviderSelect(provider)}>
                <div className="provider-item__left">
                  <img src={getProviderIcon(provider.id)} alt={provider.name} />
                </div>
                <div className="provider-item__right">
                  <span className="provider-item__name">{provider.name}</span>
                  <span className="provider-item__country">{provider.countryName}</span>
                </div>
                <div className="provider-item__arrow" />
              </li>
            ))}
          </ul>
        )}
        <div className="provider-selection__content__bottom">
          <p className={`powered-by ${!sgncBrand ? 'powered-by--hidden' : ''}`}>
            <span className="foo-logos" />
          </p>
        </div>
      </div>
    </div>
  )
})

import axios from "axios";
import * as React from "react";

import { useTranslation } from "react-i18next";
import "~i18n/config";

import { observer } from "mobx-react";
import { useContext, useState } from "react";
import { StoreContext } from "~components/App";
import { getApiOrigin } from "~methods/getApiOrigin";
import { GridSelect } from "../gridSelect/gridSelect";
import { Spinner } from "../spinner/spinner";
import "./styles.scss";

export const BankSelection: React.FC = observer(() => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const store = useContext(StoreContext);
  const { selectedProvider, sgncBrand } = store.AppState;

  const handleContinue = (method: string): void => {
    window.location.href = `${getApiOrigin()}/visa/proceedProvider/${selectedProvider}?method=${method}`;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleListData = (listData: any): void => {
    const allCategories = Object.keys(listData).map((category) => {
      return listData[category].map(
        (value: { issuerName: string; issuerID: string }) => {
          return {
            name: value.issuerName,
            value: value.issuerID,
          };
        }
      );
    });

    const output = [].concat([], ...allCategories);

    setList(output);
  };

  React.useEffect(() => {
    const fetchList = async (): Promise<void> => {
      try {
        const result = await axios.request({
          method: "GET",
          url: `${getApiOrigin()}/banks/${selectedProvider}`,
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        });

        handleListData(result.data);
        setLoading(false);
      } catch (e) {
        store.AppState.setActivePage("error");
      }
    };
    fetchList();
  }, []);

  return (
    <div className="bank-selection">
      <div
        className={`bank-selection__banner bank-selection__banner--${selectedProvider}`}
      >
        <div className={`provider-logo provider-logo--${selectedProvider}`} />
      </div>
      <div className="bank-selection__data">
        {selectedProvider === "ftn" ? (
          <>
            <h2>{t("bankSelection.ftnHeader")}</h2>
            <p>
              {t("bankSelection.descriptionFtn")}{" "}
              <a
                rel="noreferrer"
                href="https://developer.signicat.com/enterprise/identity-methods/ftn/ftn-info-FI/"
                target="_blank"
              >
                {t("bankSelection.descriptionFtnLink")}
              </a>
            </p>
          </>
        ) : (
          <>
            <h2>{t("bankSelection.header")}</h2>
            <p>{t("bankSelection.description")}</p>
          </>
        )}
        {loading ? (
          <div className="block-centered">
            <Spinner />
          </div>
        ) : selectedProvider === "ftn" ? (
          <GridSelect
            items={list}
            handleClick={(item: { value: string }): void =>
              handleContinue(item.value)
            }
          />
        ) : (
          <GridSelect
            items={list}
            handleClick={(item: { value: string }): void =>
              handleContinue(item.value)
            }
          />
        )}
      </div>
      <div className="bank-selection__bottom">
        <p
          className={`powered-by ${
            selectedProvider === "ftn" ? "ftn-foo" : ""
          } ${!sgncBrand ? "powered-by--hidden" : ""}`}
        >
          <span className="foo-logos" />
        </p>
      </div>
    </div>
  );
});

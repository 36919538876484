import React, { createContext, useContext, useEffect } from 'react'

import { getQueryVariable } from '~methods/getQueryVariable'
import './styles.scss'
import { rootStore } from '~stores/Root.store'
import { Content } from '~components/content'

export const StoreContext = createContext(rootStore)

export const App: React.FC = () => {
  const store = useContext(StoreContext)

  useEffect(() => {
    const sgncBrand = getQueryVariable('sgncBrand')
    sgncBrand && store.AppState.setsgncBrand(sgncBrand === 'true')
  }, [])

  return (
    <StoreContext.Provider value={rootStore}>
      <div className="app">
        <Content />
      </div>
    </StoreContext.Provider>
  )
}
